import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, from, map, of, switchMap } from 'rxjs';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { MxeReducers } from 'src/app/core/store/reducers';


@Component({
  selector: 'app-user-manual-page',
  templateUrl: './user-manual-page.component.html',
  styleUrls: ['./user-manual-page.component.scss']
})
export class UserManualPageComponent implements OnInit {
  countryCode: string
  languageId : string
  private countryCode$: Subscription;
  private languageId$: Subscription;
  
  // Update fileName with the current PDF fileName
  manuals: any[] = [{
      title: "Full user manual",
      lastUpdate: "17/12/2024",
      fileName: `MXE_Guidelines_2024.12.17.pdf`
    },
    {
      title: "New features user manual",
      lastUpdate: "10/04/2024",
      fileName: `MXE_Abbreviated Guidelines_2024.04.10.pdf`
    },
    {
      title: "New BE architecture guidelines",
      lastUpdate: "10/04/2024",
      fileName: `MXE_New BE Architecture_rules.pdf`
    },
    {
      title: "Bulletin",
      lastUpdate: "11/12/2024",
      fileName: `MXEBulletin20241211.pdf`
    }
  ]

  constructor(
    public uiCommonService: UiCommonService,
    private metaTagService: Meta,
    private store: Store<MxeReducers>,
  ) { }

  ngOnInit(): void {
    this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
      countryCode => this.countryCode = ''+countryCode
    )
    this.languageId$ = this.store.select(s => s.appState.languageId).subscribe(
      lang => this.languageId = lang
    )
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    this.initAdobeTracker();
  }

  

  getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  openManualPdf(manual:{title: string,fileName: string}){
    const fullPath = `../assets/user-guidlines/${manual.fileName}`
    window.open(fullPath, '_blank');
  }

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "user-guidlines-page", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }


}

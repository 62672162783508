export const environment = {
  environment: 'production',
  develop: false,
  production: true,
  maserati_services_endpoint: 'https://www.configurator.maserati.com',
  maserati_instock_endpoint: 'https://www.maserati.com',
  autobahn_configuration: {
    broker_endpoint: 'wss://mxe.maserati.com/ws',
    realm: 'realm1',
    max_retries: 10,
    heartbeat: {
      heartBeatTimer: 20000,
      heartbeat_max_retries: 4,
      heartbeat_retry_after: 20000
    },
    pairing_streaming_session_retry_after: 5000,
    pairing_not_compressed_message_max_size: 1000
  },
  authentication: {
    maserati_authentication_endpoint: "https://sts.fiatgroup.com/adfs/oauth2",
    client_id: '16b3bba0-0546-471f-9c5e-24399ac21804',
    resource: 'MXE',
    redirect_uri: 'https://mxe.maserati.com/mxe/oauth/redirecturi',
  },
  monkeyWay: {
    idleTime: 300, //seconds
    localStreamingOptions: {
      baseUrl: 'https://session.maserati.gorillastreaming.com',
      appEnvId: '35dbf9b0-5c2a-43c8-afb8-ec46a4cc2e7f',
      apiKey: ""
    }
  },
  homepage_url: 'https://mxe.maserati.com',
  image_repo_url: 'https://www.configurator.maserati.com/ccbe/public/api/imageMapper/repo',
  enable_monkey_way_streaming: true,
  photocopier_endpoint: 'https://ph.cloud.maserati.com',
  country_selector_enabled: false,
  maseratiWsEndpoint: 'https://ws.maserati.com',
  endpointsaveWTLjson : 'https://wtl.maserati.com/wtl/saveWTLjson.jsp',
  endpointModelsOfInterest: 'https://wtl.maserati.com/wtl//jsonGenericLov.jsp',
  sentry_configuration: {
    dsn: "https://409e2aa350368b0a24510ad8701b40f2@o4508046702477312.ingest.de.sentry.io/4508256155271248",
    tracePropagationTargets: [/^https:\/\/mxe.maserati.com(\/.*)?$/, /^https:\/\/\w{8}\.production\.local\.workers\.mxe-backend\.com(\/.*)?$/],
    replaysSessionSampleRate: 0.1,
    environment: 'production'
  }
  
};

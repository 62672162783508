import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { ServiceStatus } from 'src/app/core/models/maserati-service-status';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-loading-services-page',
  templateUrl: './loading-services-page.component.html',
  styleUrls: ['./loading-services-page.component.scss']
})
export class LoadingServicesPageComponent implements OnInit {

  @Input() isMainScreen : boolean
  @Input() screenCastActive
  @Input() ephemeralDeviceID: string
  @Input() showRefreshLabel : boolean

  @Output() refreshPageEvent = new EventEmitter()
  @Output() servicesCompletedEventEmitter = new EventEmitter()
  @Output() openTridentModal = new EventEmitter();
  SERVICE_STATUS = ServiceStatus;


  @Input() carGridStatus: ServiceStatus = ServiceStatus.pending;
  @Input() interfaceStatus: ServiceStatus = ServiceStatus.pending;
  @Input() streamingStatus: ServiceStatus = ServiceStatus.pending;

  private carGridStatus$ = new BehaviorSubject<ServiceStatus>(ServiceStatus.pending)
  private interfaceStatus$ = new BehaviorSubject<ServiceStatus>(ServiceStatus.pending)
  private streamingStatus$ = new BehaviorSubject<ServiceStatus>(ServiceStatus.pending)
  
  loadingPageDisplayed : boolean = true;

  servicesStatus$ : Subscription;

  constructor(
    private chg: ChangeDetectorRef,
    private autobahnClient: AutobahnClient,
    private uiCommonService: UiCommonService
  ) {
    this.servicesStatus$ = combineLatest([this.carGridStatus$,this.interfaceStatus$,this.streamingStatus$])
    .subscribe( ([carGridStatus,interfaceStatus,streamingStatus]) => {
      if(carGridStatus == ServiceStatus.completed && interfaceStatus == ServiceStatus.completed && streamingStatus != ServiceStatus.pending){
        setTimeout(() => {
          this.loadingPageDisplayed = false;
          this.servicesCompletedEventEmitter.emit(true)
          if(this.screenCastActive){
            this.autobahnClient.setDisplayLoadingServicesPage(this.ephemeralDeviceID,this.loadingPageDisplayed)
          }
        },1000);
      }
    })
  }

  ngOnInit(): void {    
    if(this.screenCastActive){
      this.autobahnClient.setDisplayLoadingServicesPage(this.ephemeralDeviceID,this.loadingPageDisplayed)
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['carGridStatus']){
      this.carGridStatus$.next(changes['carGridStatus'].currentValue)
    }
    if(changes['interfaceStatus']){
      this.interfaceStatus$.next(changes['interfaceStatus'].currentValue)
    }
    if(changes['streamingStatus']){
      this.streamingStatus$.next(changes['streamingStatus'].currentValue)
    }
  }

  ngOnDestroy(){
    this.servicesStatus$?.unsubscribe();
    this.carGridStatus$?.unsubscribe();
    this.interfaceStatus$?.unsubscribe();
    this.streamingStatus$?.unsubscribe();
  }


  getLabelDefValue(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  refreshPage(){
    this.refreshPageEvent.emit()
  }

  openTridenteModal(){
    this.openTridentModal.emit()
  }
  getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }
}

import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UiCommonService } from './ui-common.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationStateService {
  private navigationAllowed = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private uiCommonService: UiCommonService
  ){}

  setNavigationAllowed(allowed: boolean): void {
    this.navigationAllowed.next(allowed);
  }

  isNavigationAllowed(): Observable<boolean> {
    return this.navigationAllowed.asObservable();
  }

  isNavigationAllowedSync(): boolean {
    return this.navigationAllowed.getValue();
  }

  /**
   * Generic wrapper of the router.
   * @param route Può essere un array di segmenti o una stringa.
   * @param extras Opzioni di navigazione (queryParams, state, ecc.).
   */
  public navigate(route: string | (string | undefined)[], extras?: NavigationExtras): Promise<boolean> {
    this.setNavigationAllowed(true);

    // Se la route è una stringa, trasformala in un array
    const routeSegments = typeof route === 'string' ? [route] : route;

    return this.router.navigate(routeSegments, extras).finally(() => {
      this.setNavigationAllowed(false);
    });
  }

  //for alert toast

  private toastCallback: (message: string) => void = () => {};
  private modalCallback: (message: string) => void = () => {};

  setToastCallback(callback: (message: string) => void): void {
    this.toastCallback = callback;
  }

  showToast(message: string): void {
    let translatedMessage = this.getLabelWithDefaultValue('MXE_BROWSER_BACK_ERROR', message)
    this.toastCallback(translatedMessage);
  }

  getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  setModalCallback(callback: (message: string) => void): void {
    this.modalCallback = callback;
  }

  showModal(message: string): void {
    this.modalCallback(message);
  }
}


<div class="mxe__loader-poster" *ngIf="loadingPageDisplayed">
    <div class="mxe__loader-background">
        <img class="logo-maserati" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg"
            alt="Maserati" (click)="openTridenteModal()" />
        <div class="title-container">
            <div class="row-container">
                <div class="italic-word">{{getLabelWithDefaultValue('MXE_LOADING_PAGE_LOADING',"Loading")}}</div>&nbsp;<div>{{getLabelWithDefaultValue('MXE_LOADING_PAGE_YOUR',"your")}}</div>
            </div>
            <div class="row-container">
                <div>MXE</div>&nbsp;<div class="italic-word">{{getLabelWithDefaultValue('MXE_LOADING_PAGE_EXPERIENCE',"experience")}}</div>
            </div>
        </div>
        <div class="services-container">
            <div class="service">
                <img *ngIf="carGridStatus != SERVICE_STATUS.failed" class="service-img"
                    src="../../../assets/icons/mxe-ico_product-rules.svg">
                <img *ngIf="carGridStatus == SERVICE_STATUS.failed" class="service-img"
                    src="../../../assets/icons/mxe-ico_product-rules-red.svg">
                <div class="text-container" [ngClass]="{'error': carGridStatus == SERVICE_STATUS.failed}">{{getLabelWithDefaultValue('MXE_LOADING_PAGE_PRODUCT_RULES_LOADING',"Product Rules Loading")}}</div>
                <span class="loader" *ngIf="carGridStatus == SERVICE_STATUS.pending"></span>
                <span class="circle-full" *ngIf="carGridStatus == SERVICE_STATUS.completed">
                    <svg class="service-check" width="22" height="22" viewBox="0 0 11 11" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="left-to-right-blue-cg">
                                <stop offset="0" stop-color="#29B8C2">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                                <stop offset="0" stop-color="rgba(255,255,255,0)">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                            </linearGradient>
                        </defs>
                        <path class="path" fill="url(#left-to-right-blue-cg)" stroke-width="0.1" opacity="1"
                            d="M10.6937 1.62475L3.58892 10.5073L2.79887 9.69919L2.77959 9.67944L2.72607 9.62442C2.67971 9.5767 2.61273 9.50757 2.5296 9.4213C2.36342 9.24886 2.13228 9.00751 1.87212 8.73154C1.35576 8.18381 0.710434 7.48426 0.233521 6.91509L1.76651 5.63058C2.2037 6.15235 2.81532 6.81643 3.32739 7.35962C3.36752 7.40218 3.40694 7.44391 3.44553 7.48466L9.13188 0.375488L10.6937 1.62475Z" />
                    </svg>
                </span>
                <span class="circle-error" *ngIf="carGridStatus == SERVICE_STATUS.failed">
                    <svg class="service-check" width="11" height="10" viewBox="0 0 11 10" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="left-to-right-red-cg">
                                <stop offset="0" stop-color="#FF3F3F">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                                <stop offset="0" stop-color="rgba(255,255,255,0)">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                            </linearGradient>
                        </defs>
                        <path class="path" fill="url(#left-to-right-red-cg)" stroke-width="0.1" opacity="1"
                            d="M1.66675 10L0.666748 9L4.66675 5L0.666748 1L1.66675 0L5.66675 4L9.66675 0L10.6667 1L6.66675 5L10.6667 9L9.66675 10L5.66675 6L1.66675 10Z" />
                    </svg>
                </span>

            </div>
            <div class="border-container">
                <div class="bordered"></div>
            </div>
            <div class="service">
                <img *ngIf="interfaceStatus != SERVICE_STATUS.failed" class="service-img"
                    src="../../../assets/icons/mxe-ico_user-interface.svg">
                <img *ngIf="interfaceStatus == SERVICE_STATUS.failed" class="service-img"
                    src="../../../assets/icons/mxe-ico_user-interface-red.svg">
                <div class="text-container" [ngClass]="{'error': interfaceStatus == SERVICE_STATUS.failed}">
                    {{getLabelWithDefaultValue('MXE_LOADING_PAGE_USER_INTERFACE_LOADING',"User Interface Loading")}}</div>
                <span class="loader" *ngIf="interfaceStatus == SERVICE_STATUS.pending"></span>
                <span class="circle-full" *ngIf="interfaceStatus == SERVICE_STATUS.completed">
                    <svg class="service-check" width="22" height="22" viewBox="0 0 11 11" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="left-to-right-blue-int">
                                <stop offset="0" stop-color="#29B8C2">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                                <stop offset="0" stop-color="rgba(255,255,255,0)">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                            </linearGradient>
                        </defs>
                        <path class="path" fill="url(#left-to-right-blue-int)" stroke-width="0.1" opacity="1"
                            d="M10.6937 1.62475L3.58892 10.5073L2.79887 9.69919L2.77959 9.67944L2.72607 9.62442C2.67971 9.5767 2.61273 9.50757 2.5296 9.4213C2.36342 9.24886 2.13228 9.00751 1.87212 8.73154C1.35576 8.18381 0.710434 7.48426 0.233521 6.91509L1.76651 5.63058C2.2037 6.15235 2.81532 6.81643 3.32739 7.35962C3.36752 7.40218 3.40694 7.44391 3.44553 7.48466L9.13188 0.375488L10.6937 1.62475Z" />
                    </svg>
                </span>
                <span class="circle-error" *ngIf="interfaceStatus == SERVICE_STATUS.failed">
                    <svg class="service-check" width="11" height="10" viewBox="0 0 11 10" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="left-to-right-red-int">
                                <stop offset="0" stop-color="#FF3F3F">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                                <stop offset="0" stop-color="rgba(255,255,255,0)">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                            </linearGradient>
                        </defs>
                        <path class="path" fill="url(#left-to-right-red-int)" stroke-width="0.1" opacity="1"
                            d="M1.66675 10L0.666748 9L4.66675 5L0.666748 1L1.66675 0L5.66675 4L9.66675 0L10.6667 1L6.66675 5L10.6667 9L9.66675 10L5.66675 6L1.66675 10Z" />
                    </svg>
                </span>
            </div>
            <div class="border-container">
                <div class="bordered"></div>
            </div>
            <div class="service">
                <img *ngIf="streamingStatus != SERVICE_STATUS.failed" class="service-img"
                    src="../../../assets/icons/mxe-ico_model-streaming.svg">
                <img *ngIf="streamingStatus == SERVICE_STATUS.failed" class="service-img"
                    src="../../../assets/icons/mxe-ico_model-streaming-red.svg">
                <div class="text-container" [ngClass]="{'error': streamingStatus == SERVICE_STATUS.failed}">
                    {{getLabelWithDefaultValue('MXE_LOADING_PAGE_MODEL_STREAMING_LOADING',"Model Streaming Loading")}}</div>
                <span class="loader" *ngIf="streamingStatus == SERVICE_STATUS.pending"></span>
                <span class="circle-full" *ngIf="streamingStatus == SERVICE_STATUS.completed">
                    <svg class="service-check" width="22" height="22" viewBox="0 0 11 11" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="left-to-right-blue-str">
                                <stop offset="0" stop-color="#29B8C2">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                                <stop offset="0" stop-color="rgba(255,255,255,0)">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                            </linearGradient>
                        </defs>
                        <path class="path" fill="url(#left-to-right-blue-str)" stroke-width="0.1" opacity="1"
                            d="M10.6937 1.62475L3.58892 10.5073L2.79887 9.69919L2.77959 9.67944L2.72607 9.62442C2.67971 9.5767 2.61273 9.50757 2.5296 9.4213C2.36342 9.24886 2.13228 9.00751 1.87212 8.73154C1.35576 8.18381 0.710434 7.48426 0.233521 6.91509L1.76651 5.63058C2.2037 6.15235 2.81532 6.81643 3.32739 7.35962C3.36752 7.40218 3.40694 7.44391 3.44553 7.48466L9.13188 0.375488L10.6937 1.62475Z" />
                    </svg>
                </span>
                <span class="circle-error" *ngIf="streamingStatus == SERVICE_STATUS.failed">
                    <svg class="service-check" width="11" height="10" viewBox="0 0 11 10" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="left-to-right-red-str">
                                <stop offset="0" stop-color="#FF3F3F">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                                <stop offset="0" stop-color="rgba(255,255,255,0)">
                                    <animate dur="1s" attributeName="offset" fill="freeze" from="0" to="1" />
                                </stop>
                            </linearGradient>
                        </defs>
                        <path class="path" fill="url(#left-to-right-red-str)" stroke-width="0.1" opacity="1"
                            d="M1.66675 10L0.666748 9L4.66675 5L0.666748 1L1.66675 0L5.66675 4L9.66675 0L10.6667 1L6.66675 5L10.6667 9L9.66675 10L5.66675 6L1.66675 10Z" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="refresh-container" *ngIf="showRefreshLabel"> 
            <span>{{getLabelWithDefaultValue('MXE_LOADING_PAGE_ERROR',"Something went wrong! Try refreshing or go back by tapping on Tridente")}}</span>
        </div>
        <button class="btn btn-trasparent refresh-button" (click)="refreshPage()">
            <span class="refresh-button-text">{{getLabelDefValue('REFRESH','Refresh')}}</span>
            <img class="refresh-img" src="../../../assets/icons/mxe-ico-refresh.svg">
        </button>

    </div>
</div>